<template>

	<!-- Projects Table Column -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :flex="8">
					<h5 class="font-semibold m-0">Payment History</h5>
				</a-col>
				<a-col :flex="2" style="text-align:right;">
					<DashboardControls />
				</a-col>
			</a-row>
		</template>
		
		<a-table :columns="columns" :data-source="data" :pagination="false" rowKey="_id">
			<template slot="name" slot-scope="text">
				<a>{{ text }}</a>
			</template>
		</a-table>
	</a-card>
	<!-- / Projects Table Column -->

</template>

<script>
	import DashboardControls from '../../components/Widgets/DashboardControls' ;

	export default ({
		components: {
			DashboardControls
		},
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				// Active button for the "Projects" table's card header radio button group.
				projectHeaderBtns: 'all',
			}
		},
	})

</script>