<template>

		<a-button-group size="small">
            <a-button type="primary" v-on:click="doRefresh">
                <a-icon type="redo" />Refresh
            </a-button>
            <a-button type="primary" v-on:click="doDownload">
                Download<a-icon type="cloud-download" />
            </a-button>
        </a-button-group>

</template>

<script>
	export default ({
		methods: {
			doRefresh() {
				this.$store.dispatch('dash/refresh')
			},
			doDownload() {
				this.$store.dispatch('dash/download')
			}
		}
	})

</script>